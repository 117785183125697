import scrypt from 'scrypt-js';

class Service {
    encrypt(data) {
        const encoder = new TextEncoder();
        const arrays = ["CATNA", "ACTNA", "ATCNA", "ATNCA"];
        let dataLength = 4096;
        let result;
        for (let array of arrays.map(array => encoder.encode(array))) {
            result = scrypt.syncScrypt(encoder.encode(data), array, 16384, 8, 1, dataLength);
            dataLength -= 1024;
        }
    
        return result.toString();
    }
}

export default Service;