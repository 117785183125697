import React, { useState } from 'react';
import { Avatar, Button, FormControl, TextField, Typography } from '@mui/material';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Service from '../Services/Encrypt/EncryptService';

function Register({ onCancel, onSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@#$%^&+=!])(?!.*\\s)(?!.*${username}).{12,}$`);
        if (username.trim() === '' || password.trim() === '') {
            setError('Username or password cannot be empty');
        } else {
            if (regex.test(password)) {
                const requestData = {
                    username: username,
                    password: new Service().encrypt(password)
                };
                axios.post('https://bopokemon.bsite.net/api/register', requestData, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.status === 200) {
                        onSuccess();
                        toast.success('Registration successful!');
                    } else {
                        setError('Registration failed. Please try again!');
                    }
                }).catch(error => {
                    setError('An error occurred while registering. Please try again!');
                    console.log(error);
                });
            } 
            else
                setError('The password is not in the correct format.');
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <form onSubmit={handleSubmit} style={{ width: '30vw' }}>
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
                <AppRegistrationOutlinedIcon />
            </Avatar>
            <h1>Register</h1>
            <FormControl fullWidth>
                <TextField
                    label="Username"
                    name="username"
                    value={username}
                    onChange={handleUsernameChange}
                    variant="outlined"
                    margin="normal"
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    type="password"
                    label="Password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    margin="normal"
                />
            </FormControl>
            <Typography variant="h6" color="warning">Your password must meet the following conditions:</Typography>
            <Typography variant="body1" color="warning" sx={{ ml: 4 }}>1/ At least one lowercase letter.</Typography>
            <Typography variant="body1" color="warning" sx={{ ml: 4 }}>2/ At least one uppercase letter.</Typography>
            <Typography variant="body1" color="warning" sx={{ ml: 4 }}>3/ At least one digit.</Typography>
            <Typography variant="body1" color="warning" sx={{ ml: 4 }}>4/ At least one special character such as @, #, $, %, ^, &, +, and =.</Typography>
            <Typography variant="body1" color="warning" sx={{ ml: 4 }}>5/ No whitespace allowed.</Typography>
            <Typography variant="body1" color="warning" sx={{ ml: 4 }}>6/ Cannot contain the username.</Typography>
            <Typography variant="body1" color="warning" sx={{ ml: 4 }}>7/ At least 12 characters.</Typography>
            
            {error && <Typography color="error" variant="body1" sx={{ width: '100%' }}>{error}</Typography>}
            <Button type="submit" variant="contained" sx={{ mt: 2, width: '100%' }}>Register</Button>
            <Button type="button" variant="contained" sx={{ mt: 2, width: '100%' }} onClick={handleCancel}>Cancel</Button>
        </form>
    );
}

export default Register;