import React, { useState } from 'react';
import Dashboard from '../Dashboard/Dashboard';
import Service from '../Services/Encrypt/EncryptService';

import { Avatar, Button, FormControl, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login({ onSubmit }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleGetOTP = () => {
    const requestData = `Username=${username}&OtpLength=6`;
    // const salt = CryptoJS.lib.WordArray.random(128/8).toString();
    // const hashedPassword = CryptoJS.SHA256(password + salt).toString();
    axios.post('https://bopokemon.bsite.net/api/otp', requestData, {
      headers: {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        if (response.status === 200) {
          toast.success(response.data);
        } else {
          setError(response.data.Message);
        }
      })
      .catch(error => {
        setError('An error occurred while getting the OTP. Please try again!');
        console.log(error);
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@#$%^&+=!])(?!.*\\s)(?!.*${username}).{12,}$`);
    const encryptData = regex.test(password) ? new Service().encrypt(password) : password;
    const requestData = `username=${username}&password=${encryptData}`;
    axios.post('https://bopokemon.bsite.net/api/login', requestData, {
      headers: {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        if (response.status === 200) {
          setLoggedIn(true);
          onSubmit();
          localStorage.setItem('loggedIn', true);
          localStorage.setItem('token', response.data.accessToken);
        } else {
          setError('Login failed. Please try again!');
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          setError(error.response.data.Message);       
        } else {
          setError('An error occurred while logging in. Please try again!');
        }        
      });
  };

  if (loggedIn) {
    return <Dashboard />;
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: '30vw' }}>
      <Avatar sx={{ bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <h1>Login</h1>
      <FormControl fullWidth>
        <TextField
          label="Username"
          name="username"
          value={username}
          onChange={handleUsernameChange}
          variant="outlined"
          margin="normal"
        />
      </FormControl>
      <FormControl fullWidth sx={{ display: 'inline-block' }}>
        <TextField
          type="password"
          label="Password or OTP"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          variant="outlined"
          margin="normal"
          sx={{ width: '21vw', marginRight: '1vw' }}
        />
        <Button type="button" variant="contained" sx={{ mt: 3, width: '8vw' }} onClick={handleGetOTP}>Get OTP</Button>
      </FormControl>
      {error && <Typography color="error" variant="body1" sx={{ width: '100%' }}>{error}</Typography>}
      <Button type="submit" variant="contained" sx={{ mt: 2, width: '100%' }}>Login</Button>
    </form>
  );
}

export default Login;
