import React from 'react';
import './App.css';
import Typography from '@mui/material/Typography';

function Copyright(props) {
  return (
    <Typography variant="h6" color="text.secondary" align="center" {...props}>
      {'© Copyright by CATNA in '}
      {new Date().getFullYear()}
      {'. All rights reserved'}
    </Typography>
  );
}

export default Copyright;
