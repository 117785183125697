import React, { useState } from 'react';
import { Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import Register from './Register/Register';
import Login from './Login/Login';
import Copyright from './Copyright';

function App() {
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showRegisterButton, setShowRegisterButton] = useState(true);
  const [showCopyright, setShowCopyright] = useState(true);

  const handleRegister = () => {
    setShowRegisterForm(true);
    setShowRegisterButton(false);
    setShowCopyright(true);
  };

  const handleCancelRegistration = () => {
    setShowRegisterForm(false);
    setShowRegisterButton(true);
  };

  const handleLogin = () => {
    setShowRegisterForm(false);
    setShowRegisterButton(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      {showRegisterForm ? <Register onCancel={handleCancelRegistration} onSuccess={handleCancelRegistration} /> : <Login onSubmit={handleLogin} />}
      {showRegisterButton && <Button type="submit" variant="contained" sx={{ mt: 2, width: '30vw' }} onClick={handleRegister}>Register</Button>}
      {showCopyright && <Copyright sx={{ mt: 4, mb: 4 }} />}
      <ToastContainer />
    </div>
  );
}

export default App;
