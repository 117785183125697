import { Button } from '@mui/material';
import App from '../App';

function Dashboard() {
    const isLoggedIn = localStorage.getItem('loggedIn');
    if (isLoggedIn === 'true') {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    margin: '2vh 0 0 0'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <h1>Please import your cURL.</h1>
                    <Button type="submit" variant="contained">Import</Button>
                </div>
            </div>
        );
    } else {
        return <App />
    }
}

export default Dashboard;